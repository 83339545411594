import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import classes from "./user.module.css";
import classesOne from "../Property/propertys.module.css";
import {
  Card,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  Pagination,
  Box,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Popover,
  Autocomplete,
} from "@mui/material";
import {
  FileIcon,
  FilterIcon,
  ListFilterIcon,
  NumberInputIcon,
  SearchIcons,
  UserManageIcon,
  WhiteSearchIcon,
} from "../../Assets/Icons/icons";
import filter_icon from "../../Assets/Icons/Filter.svg"
import { Numeric } from "../../Utilities/commonFunctions";
import UserTable from "./userTable";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  exportCustomerList,
  getAllCustomers,
} from "../../Redux/Actions/customerActions";
import {
  getAllFiltersList,
  getFeildsList,
} from "../../Redux/Actions/propertyAction";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import { range } from "../../Utilities/commonConstants";
import moment from "moment";
import { FaFilterCircleXmark, FaXmark } from "react-icons/fa6";
import { CREATE_CUSTOMER_REQUEST_SUCCESS } from "../../Redux/Constants/customerConstants";
import { toast } from "react-toastify";

let test_status = [
  { _id: "Active", text: "Active" },
  { _id: "Inactive", text: "Inactive" },
  { _id: "Blocked", text: "Blocked" },
];

const UserIndex = () => {
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [count, setCount] = useState("10");
  const [pagination, setPagination] = useState(1);
  const [selectTableName, setSelectTableName] = useState([
    {
      value: "User Name",
      active: true,
      key: "User Name",
    },
    { value: "Mobile", active: true, key: "Mobile" },
    { value: "Email ID", active: true, key: "Email ID" },
    { value: "Registration Date", active: true, key: "Registration Date" },
    { value: "Location", active: true, key: "Location" },
    { value: "Last Login", active: true, key: "Last Login" },
    { value: "Status", active: true, key: "Status" },
    { value: "User ID", active: false, key: "User ID" },
    { value: "User Type", active: false, key: "User Type" },
    { value: "Properties Posted", active: false, key: "Properties Posted" },
  ]);
  const [inputs, setInputs] = useState({
    location: [],
    user_status: [],
    user_type: [],
    nationality: [],
    user: [],
  });
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [focusedElement, setFocusedElement] = useState(null);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const { totalCount } = useSelector((state) => state.customerData);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filtersList, feildsList } = useSelector(
    (state) => state.propertyData
  );
  const { success } = useSelector((state) => state.customerData);

  const handleChangePagination = (e, count) => {
    setPagination(count);
  };

  const handleChange = async (field, value, list) => {
    if (value.includes("all")) {
      if (inputs[field].length === list.length) {
        setInputs({ ...inputs, [field]: [] });
        return;
      }
      let data;
      if (field === "location") {
        data = await list.map((item) => item);
      } else if (field === "user_status") {
        data = await list.map((item) => item?.text);
      } else if (field === "user_type") {
        data = await list.map((item) => item); // Since user_type is directly 'Agent'/'Owner'
      } else if (field === "nationality") {
        data = await list.map((item) => item); // Since user_type is directly 'Agent'/'Owner'
      } else if (field === "user") {
        data = await list.map((item) => item); // Since user_type is directly 'Agent'/'Owner'
      } else {
        data = await list.map((item) => item._id);
      }

      setInputs({ ...inputs, [field]: data });
      return;
    }
    setInputs({ ...inputs, [field]: value });
  };

  function handleGetUsers() {
    dispatch(pageLoader(true));
    dispatch(
      getAllCustomers(
        setErrorAlert,
        count,
        pagination,
        searchValue,
        inputs,
        fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
        toDate ? dayjs(toDate).format("YYYY-MM-DD") : ""
      )
    );
  }
  console.log("checkedUsers =", checkedUsers);
  const handleClearFilter = async () => {
    const filter = {
      location: [],
      user_status: [],
      user_type: [],
      nationality: [],
      user: [],
    };
    setSelectedDate();
    setFromDate();
    setToDate();
    setInputs(filter);
    setSearchValue("");
    // handleGetPropertyList(filter);

    let _inputs = {
      location: [],
      user_status: [],
      user_type: [],
      nationality: [],
      user: [],
    };
    let _fromDate = "";
    let _toDate = "";
    let _searchValue = "";
    dispatch(pageLoader(true));
    dispatch(
      getAllCustomers(
        setErrorAlert,
        count,
        pagination,
        _searchValue,
        _inputs,
        _fromDate ? dayjs(_fromDate).format("YYYY-MM-DD") : "",
        _toDate ? dayjs(_toDate).format("YYYY-MM-DD") : ""
      )
    );
  };

  const handleSearch = (e) => {
    dispatch(pageLoader(true));
    dispatch(
      getAllCustomers(
        setErrorAlert,
        count,
        pagination,
        searchValue,
        inputs,
        fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
        toDate ? dayjs(toDate).format("YYYY-MM-DD") : ""
      )
    );
  };

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(
      getAllCustomers(
        setErrorAlert,
        count,
        pagination,
        searchValue,
        inputs,
        fromDate,
        toDate
      )
    );
    dispatch(getAllFiltersList(setErrorAlert));
    dispatch(getFeildsList(setErrorAlert));
    dispatch({
      type: CREATE_CUSTOMER_REQUEST_SUCCESS,
      payload: null,
    });
  }, [count, pagination, searchValue, success]);

  const handleDownloadReport = () => {
    dispatch(pageLoader(true));
    dispatch(exportCustomerList(setErrorAlert, checkedUsers, setCheckedUsers));
  };

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  useEffect(() => {
    if (fromDate !== undefined && toDate !== undefined) {
      setSelectedDate(
        `${moment(fromDate).format("YYYY-MM-DD")} to ${moment(toDate).format(
          "YYYY-MM-DD"
        )}`
      );
    }
  }, [fromDate, toDate]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dynamicHeaders = selectTableName
    .filter((data) => data.active)
    .map((data) => data.key);

  const handleSelectTable = (data) => {
    const check = [ "Email ID", "Mobile", "User Name"];

    // Count the currently active items
    const activeCount = selectTableName.filter((item) => item.active).length;

    const checkedList = selectTableName.map((item) => {
      if (item.value === data.value) {
        // Prevent deselection of required items
        if (check.includes(item.value) && item.active) {
          toast.error(`"${item.value}" cannot be deselected as it is required`);
          return item; // Return item as is without toggling
        }

        // Prevent selecting more than 7 items
        if (!item.active && activeCount >= 7) {
          toast.error("You can only select up to 7 items");
          return item; // Return item as is without toggling
        }

        // Toggle the active state
        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });

    setSelectTableName(checkedList);
  };
  return (
    <div>
      <Typography className={classes.userIndexMainHeader}>
        Manage Users
      </Typography>
      <Card className={`mt-4 ${classes.TableContainer}`}>
        <Box p={3} pt={2}>
          <div className={classes.filterHeaderdiv}>
            <Typography className={classes.sub_header}>
              <ListFilterIcon /> Filter
            </Typography>
            <Typography
              className={`${classes.sub_header} ${classes.clearFilterBtn}`}
              onClick={handleClearFilter}
            >
              <FaFilterCircleXmark /> Clear Filter
            </Typography>
          </div>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  {/* <InputLabel
                    id="demo-simple-select-label"
                    // className={`${classes.InputLabel} ${
                    //   (focusedElement === "owner_type" ||
                    //     inputs["owner_type"]?.length > 0) &&
                    //   classes.focuedValueLable
                    // }`}
                  >
                    User List
                  </InputLabel> */}
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    value={inputs["location"]}
                    onChange={(event, value) => {
                      handleChange(
                        "location",
                        value ? value : "",
                        feildsList?.location
                      );
                      // setMyUser(value);
                    }}        
               
                    options={
                      filtersList &&
                      filtersList?.userLocation &&
                      filtersList?.userLocation?.length > 0
                        ? filtersList?.userLocation?.map((option) => option)
                        : [].map((option) => option)
                    }
                    renderInput={(params) => (
                      <TextField
                        label="location"
                        // placeholder="Select City"
                        className={
                          focusedElement === "location"
                            ? "my-autocomplete-focus my-autocomplete"
                            : "my-autocomplete"
                        }
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
          
            <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  {/* <InputLabel
                    id="demo-simple-select-label"
                    // className={`${classes.InputLabel} ${
                    //   (focusedElement === "owner_type" ||
                    //     inputs["owner_type"]?.length > 0) &&
                    //   classes.focuedValueLable
                    // }`}
                  >
                    User List
                  </InputLabel> */}
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    value={inputs["user"]}
                    onChange={(event, value) => {
                      handleChange(
                        "user",
                        value ? value : "",
                        feildsList?.userList
                      );
                      // setMyUser(value);
                    }}        
                    options={
                      filtersList &&
                      filtersList?.userList &&
                      filtersList?.userList?.length > 0
                        ? filtersList?.userList?.map((option) => option)
                        : [].map((option) => option)
                    }
                    renderInput={(params) => (
                      <TextField
                        label="User"
                        // placeholder="Select City"
                        className={
                          focusedElement === "User"
                            ? "my-autocomplete-focus my-autocomplete"
                            : "my-autocomplete"
                        }
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth className="form-c">
                <InputLabel
                  id="demo-simple-select-label"
                  className={`${classesOne.InputLabel} ${
                    (focusedElement === "user_status" ||
                      inputs["user_status"]?.length > 0) &&
                    classesOne.focuedValueLable
                  }`}
                >
                  User Status
                </InputLabel>
                <Select
                  variant="outlined"
                  className={`${classesOne.selectInput} ${
                    focusedElement === "user_status" && classesOne.focusedInput
                  }`}
                  onFocus={() => setFocusedElement("user_status")}
                  onBlur={() => setFocusedElement(null)}
                  key={"user_status"}
                  autoComplete="off"
                  select
                  fullWidth
                  id="user_status"
                  placeholder="Expected by"
                  name={"user_status"}
                  size="normal"
                  multiple
                  renderValue={(selected) => {
                    if (inputs["user_status"]?.length === 0) {
                      return (
                        <em style={{ color: "rgba(0,0,0,0.3)" }}>Category</em>
                      );
                    }
                    return (
                      <em style={{ textTransform: "capitalize" }}>
                        {inputs["user_status"]
                          ?.map((value) => {
                            const item = test_status.find(
                              (item) => item._id === value
                            );
                            return item?.text;
                          })
                          .join(", ")}
                      </em>
                    );
                  }}
                  value={inputs["user_status"]}
                  onChange={(e) =>
                    handleChange("user_status", e.target.value, test_status)
                  }
                  validators={["required"]} // Add this line
                  errorMessages={["Amenities is required"]} // Add this line
                >
                  <MenuItem
                    value={"all"}
                    className={`${classesOne.CheckBoxContainer} selection ${
                      inputs["user_status"]?.length === test_status?.length &&
                      classesOne.CheckBoxActiveSelectAllContainer
                    }`}
                  >
                    <Checkbox
                      sx={{ padding: 0 }}
                      color="primary"
                      checked={
                        inputs["user_status"]?.length === test_status.length
                      }
                      classesOne={{
                        checked: classesOne.checked,
                      }}
                    />
                    <span>Select All</span>
                  </MenuItem>
                  {test_status?.map((item) => (
                    <MenuItem
                      value={item?._id}
                      key={item?._id}
                      className={`${classesOne.CheckBoxContainer} selection`}
                    >
                      <Checkbox
                        sx={{ padding: 0 }}
                        color="primary"
                        checked={inputs["user_status"]?.includes(item?._id)}
                        classesOne={{
                          checked: classesOne.checked,
                        }}
                      />
                      <span
                        className={`${
                          inputs["user_status"]?.includes(item?._id) &&
                          classesOne.checkboxText
                        }`}
                      >
                        {item?.text}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth className="form-c">
                <InputLabel
                  id="user-type-label"
                  className={`${classesOne.InputLabel} ${
                    (focusedElement === "user_type" ||
                      inputs["user_type"]?.length > 0) &&
                    classesOne.focuedValueLable
                  }`}
                >
                  User Type
                </InputLabel>
                <Select
                  variant="outlined"
                  className={`${classesOne.selectInput} ${
                    focusedElement === "user_type" && classesOne.focusedInput
                  }`}
                  onFocus={() => setFocusedElement("user_type")}
                  onBlur={() => setFocusedElement(null)}
                  multiple
                  value={inputs["user_type"]}
                  onChange={(e) =>
                    handleChange("user_type", e.target.value, [
                      "Agents",
                      "Owners",
                    ])
                  }
                  renderValue={(selected) => {
                    if (inputs["user_type"]?.length === 0) {
                      return (
                        <em style={{ color: "rgba(0,0,0,0.3)" }}>User Type</em>
                      );
                    }
                    return selected.join(", ");
                  }}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={inputs["user_type"]?.length === 2}
                      color="primary"
                    />
                    <span>Select All</span>
                  </MenuItem>
                  {["Agents", "Owners"].map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        checked={inputs["user_type"]?.includes(item)}
                        color="primary"
                      />
                      <span>{item}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth className="form-c">
                <InputLabel
                  id="user-type-label"
                  className={`${classesOne.InputLabel} ${
                    (focusedElement === "nationality" ||
                      inputs["nationality"]?.length > 0) &&
                    classesOne.focuedValueLable
                  }`}
                >
                  Nationality
                </InputLabel>
                <Select
                  variant="outlined"
                  className={`${classesOne.selectInput} ${
                    focusedElement === "nationality" && classesOne.focusedInput
                  }`}
                  onFocus={() => setFocusedElement("nationality")}
                  onBlur={() => setFocusedElement(null)}
                  multiple
                  value={inputs["nationality"]}
                  onChange={(e) =>
                    handleChange("nationality", e.target.value, [
                      "India",
                      "NRI",
                    ])
                  }
                  renderValue={(selected) => {
                    if (inputs["nationality"]?.length === 0) {
                      return (
                        <em style={{ color: "rgba(0,0,0,0.3)" }}>User Type</em>
                      );
                    }
                    return selected.join(", ");
                  }}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={inputs["nationality"]?.length === 2}
                      color="primary"
                    />
                    <span>Select All</span>
                  </MenuItem>
                  {["India", "NRI"].map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        checked={inputs["nationality"]?.includes(item)}
                        color="primary"
                      />
                      <span>{item}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classesOne.datePicker1} style={{ width: "93%" }}>
                <DateRangePicker
                  initialSettings={{ ranges: range }}
                  alwaysShowCalendars={true}
                  onApply={handleEvent}
                >
                  <div>
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      size="small"
                      autoComplete="off"
                      placeholder="Select Date Period"
                      value={selectedDate ? selectedDate : ""}
                      className={`${
                        selectedDate
                          ? classesOne.dataInputTwo
                          : classesOne.dataInputTwo
                      }`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >
                              <FaRegCalendarAlt />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </DateRangePicker>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}></Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Box display={"flex"} alignItems={"center"} gap={"15px"}>
                <Button className={classes.search_btn} onClick={handleSearch}>
                  <WhiteSearchIcon /> Search
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card className={`mt-4 ${classes.TableContainer}`}>
        <div className={classes.TableHeaderContainer}>
          <div className={classes.TableHeaderLeftContainer}>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              placeholder="Search"
              className={classes.CommonTextInput}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="start"
                      className={classes.eyeIcon}
                    >
                      <SearchIcons />{" "}
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="filter"
                      edge="end"
                      className={classes.eyeIcon}
                    >
                      <FaXmark  onClick={()=>setSearchValue("")}/> {/* Replace this with the icon you want */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.countInputDiv}>
              <Typography className={classes.countText}>Show</Typography>
              <TextField
                className={classes.countInput}
                value={count}
                onChange={(e) => {
                  setCount(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                type="number"
              />
              <Typography className={classes.countText}>Entries</Typography>
            </div>
          </div>
          <div className={classes.TableHeaderRightContainer}>
            <Button
              variant="outlined"
              className={classes.userFilterBtn}
              onClick={handleDownloadReport}
            >
              <FileIcon />
              <span>Export to Excel</span>
            </Button>
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
              className={classes.option_btn}
            >
             <img src={filter_icon} alt="option_icon" />
              <span>View Options</span>
            </Button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              <div className={classes.popoverDiv_content}>
                {selectTableName.map((data) => (
                  <box
                    className={`${classes.popoverDiv_content_text} ${
                      data.active === false && classes.inactive
                    }`}
                    onClick={() => handleSelectTable(data)}
                  >
                    <Typography>{data.value}</Typography>
                  </box>
                ))}
              </div>
            </Popover>
            {/* <Button variant="outlined" className={classes.userFilterBtn}>
              <FilterIcon />
              <span>View Options</span>
            </Button> */}
          </div>
        </div>
        <div className={classes.TableContainer}>
          <UserTable
            checkedUsers={checkedUsers}
            setCheckedUsers={setCheckedUsers}
            dynamicHeaders={dynamicHeaders}
          />
        </div>
        <div className={classes.paginationContainer}>
          <Pagination
            count={Math.ceil(totalCount / parseInt(count))}
            shape="rounded"
            sx={{ textAlign: "end" }}
            onChange={handleChangePagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default UserIndex;
