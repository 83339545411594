import {
  Typography,
  Box,
  MenuItem,
  Button,
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Pagination,
  TextField,
  Popover,
} from "@mui/material";
import classes from "./propertys.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  ApproveIcon,
  ArrowsIcon,
  DeactivateIcon,
  DeleteIcon,
  DeleteIconWhite,
  DotGreenIcon,
  EditIcon,
  EditIconWhite,
  ExportIcon,
  GridIcon,
  ListtIcon,
  LocationIconLite,
  OwnerIconLite,
  PriceIconLite,
  ResidentialIconLite,
  SaleIconLite,
  SoldIcon,
  WidthIconLite,
} from "../../Assets/Icons/icons";
import MyTabelData from "./MyTabelData";
import filter_icon from "../../Assets/Icons/Filter.svg"
import { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  deleteProperty,
  downloadExportExcel,
  saveSortOrder,
} from "../../Redux/Actions/propertyAction";
import GridItem from "./gridItem";
import { range } from "../../Utilities/commonConstants";
import { toast } from "react-toastify";

function MyTableContainer(props) {
  const {
    arr,
    tableDatas,
    handleChangePagination,
    handleEvent,
    totalCount,
    selectedDate,
    handleGetPropertyList
  } = props;
  const [selectTableName, setSelectTableName] = useState([
  /* 0*/ { value: "_id", active: true, key: "Property ID" },
  /* 1*/ { value: "kind_of_property", active: true, key: "Property Type" },
  /* 2*/ { value: "offer_type", active: true, key: "Listing Type" },
  /* 3*/ { value: "location", active: true, key: "Location" },
  /* 4*/ { value: "locality", active: true, key: "Locality" },
  /* 5*/ { value: "user_type", active: false, key: "Posted By" },
  /* 6*/ { value: "user_type", active: false, key: "Owner Type" },
  /* 7*/ { value: "created_user.name", active: false, key: "User Name" },
  /* 8*/ { value: "created_user.phone", active: false, key: "Phone No" },
  /* 9*/ { value: "created_user.email", active: false, key: "Email" },
   /*10*/ { value: "Photos / Videos", active: false, key: "Photos / Videos" },
   /*11*/ { value: "price.expected_price", active: false, key: "Price" },
   /*12*/ { value: "createdAt", active: false, key: "Posted On" },
   /*13*/ { value: "property_status", active: false, key: "Status" },
  /*14*/  { value: "updatedAt", active: false, key: "Last Modified Status" },
  ]);
  const [viewType, setViewType] = useState("list");
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [checkedProps, setCheckedProps] = useState([]);
  const { tableSort } = useSelector((state) => state.propertyData);

  const handleDownloadReport = () => {
    dispatch(pageLoader(true));
    dispatch(downloadExportExcel(setErrorAlert, checkedProps, setCheckedProps));
  };
  console.log("checkedProps =", checkedProps)
  function handleCheckProps(e, datas) {
    console.log("datas =", datas)
    const { checked } = e.target;
    if (checked) {
      let existing_ids = [...checkedProps];
      existing_ids.push(datas._id);
      setCheckedProps(existing_ids);
    } else {
      let existing_ids = [...checkedProps];
      let filtering_ids = existing_ids.filter((el) => el != datas._id);
      setCheckedProps(filtering_ids);
    }
  }

  const sortByList = [
    { value: "createdAt-asc", text: "Oldest First" },
    { value: "createdAt-desc", text: "Newest First" },
    { value: "view_count-desc", text: "Most Viwed" },
    { value: "view_count-asc", text: "Least Viwed" },
  ];

  useEffect(() => {
    let my_list_view = sessionStorage.getItem("list_view");
    if (my_list_view === "grid") {
      setViewType("grid");
    } else {
      setViewType("list");
    }
  }, [])
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dynamicHeaders = selectTableName
    .filter((data) => data.active)
    .map((data) => data.key);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectTable = (data) => {
    const check = ["User Type", "Email ID", "Mobile", "User Name"];

    // Count the currently active items
    const activeCount = selectTableName.filter((item) => item.active).length;

    const checkedList = selectTableName.map((item) => {
      if (item.key === data.key) {
        // Prevent deselection of required items
        if (check.includes(item.key) && item.active) {
          toast.error(`"${item.key}" cannot be deselected as it is required`);
          return item; // Return item as is without toggling
        }

        // Prevent selecting more than 7 items
        if (!item.active && activeCount >= 6) {
          toast.error("You can only select up to 7 items");
          return item; // Return item as is without toggling
        }

        // Toggle the active state
        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });

    setSelectTableName(checkedList);
  };
  return (
    <Box>
      <Box py={1} px={2} className="d-flex-between">
        <Box className="d-flex" gap={"20px"}>
          <Typography className={classes.sub_header}>
            Showing 1-9 of 13 results
          </Typography>
          <Button className={classes.export_btn} onClick={handleDownloadReport}>
            {" "}
            <ExportIcon /> Export to Excel
          </Button>
        </Box>
        <Box className="d-flex" gap={"15px"}>
          <div className={classes.datePicker1}>
          </div>
          <Typography className={classes.sub_header}>Sort By</Typography>
          <TextField
            variant="outlined"
            autoComplete="off"
            select
            sx={{ width: "170px" }}
            id="emailInput"
            placeholder="Expected by"
            className={`my-field select-field`}
            name={"tableSort"}
            value={tableSort}
            size="normal"
            onChange={(e) => dispatch(saveSortOrder(e.target.value))}
          >

            {sortByList?.map((item, index) => {
              return (
                <MenuItem
                  value={item.value}
                  className="customize-select"
                // onClick={(e) => dispatch(saveSortOrder(item))}
                >
                  {item?.text}
                </MenuItem>
              );
            })}
          </TextField>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              scrollbarWidth: null,
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",

            }}
          >
            <div className={classes.popoverDiv_content}>
              {selectTableName.map((data) => (
                <box
                  className={`${classes.popoverDiv_content_text} ${data.active === false && classes.inactive
                    }`}
                  onClick={() => handleSelectTable(data)}
                >
                  <Typography>{data.key}</Typography>
                </box>
              ))}
            </div>
          </Popover>
          <div className={classes.view_option} onClick={handleClick} aria-describedby={id}>
            <img src={filter_icon} alt="filter icon" />
            <Typography>
              View Options
            </Typography>
          </div>
          {viewType === "list" ? (
            <Typography
              onClick={() => {
                sessionStorage.setItem("list_view", "grid");
                setViewType("grid");
              }}
              sx={{ cursor: "pointer" }}
            >
              <GridIcon />
            </Typography>
          ) : (
            <Typography
              onClick={() => {
                sessionStorage.setItem("list_view", "list");
                setViewType("list")
              }}
              sx={{ cursor: "pointer" }}
            >
              <ListtIcon />
            </Typography>
          )}
        </Box>
      </Box>
      {viewType === "list" ? (
        <TableContainer component={Paper} className={classes.TableContainer}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>
                  <p style={{ visibility: "hidden" }}>sss</p>
                </TableCell>
                {
                  dynamicHeaders.map((data, index) => {
                    return <TableCell key={index}>{dynamicHeaders[index]}</TableCell>
                  })
                }
              <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableDatas?.map((row, index) => (
                <MyTabelData row={row} index={index} handleGetPropertyList={handleGetPropertyList} checkedProps={checkedProps} handleCheckProps={handleCheckProps} selectTableName={selectTableName} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box px={2} mt={2}>
          <Grid container spacing={2}>
            {tableDatas?.map((datas) => {
              return <GridItem datas={datas} key={datas._id} handleGetPropertyList={handleGetPropertyList} />;
            })}
          </Grid>
        </Box>
      )}

      <Box
        display={"flex"}
        justifyContent={"end"}
        my={3}
        className="property_pagination"
      >
        <Pagination
          count={Math.ceil(totalCount / 10)}
          shape="rounded"
          sx={{ textAlign: "end" }}
          onChange={handleChangePagination}
        />
      </Box>
    </Box>
  );
}

export default MyTableContainer;
