import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import classes from "./propertyDetail.module.css";
import { CloseProperttIcon, DeleteIcon } from "../../Assets/Icons/icons";
import dummy_image from "../../Assets/Images/dummy_image.png";
const style = {
  bgcolor: "background.paper",
};

export default function PropertyDetails({
  ViewDetails,
  setViewDetails,
  handleOpen,
  handleClose,
  row,
  bg,
}) {
  console.log(row);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={ViewDetails}
        onClose={handleClose}
        closeAfterTransition
        // slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: bg, // Custom backdrop color
              border: "1px solid #8ca944 !important",
            },
            timeout: 500,
          },
        }}
      >
        <Fade in={ViewDetails}>
          <Box sx={style} className={classes.detail_container}>
            <div className={classes.detail_main_container}>
              <div className={classes.closeIcon} onClick={handleClose}>
                <CloseProperttIcon />
              </div>
              <p className={classes.detail_heading}>View Property</p>
              <div className={classes.main_detail_container}>
                <div className={classes.property_type}>
                  <p>Property Type</p>
                  <p>{row?.kind_of_property}</p>
                </div>
                <div className={classes.basic_details}>
                  <p>Basic Details</p>
                  <div className={classes.basic_details_container}>
                    <div className={classes.left}>
                      <p>User Name</p>
                      <p>Phone Number</p>
                      <p>Email ID</p>
                      <p>User Type</p>
                      <p>Ownership</p>
                      <p>Property Type</p>
                      <p>Looking to</p>
                    </div>
                    <div className={classes.right}>
                      <p>
                        {row?.created_user?.name ? row.created_user.name : " -"}{" "}
                      </p>
                      <p>
                        {row?.created_user?.phone
                          ? row.created_user.phone
                          : " -"}
                      </p>
                      <p>
                        {row?.created_user?.email
                          ? row.created_user.email
                          : " -"}
                      </p>
                      <p>{row?.user_type ? row.user_type : " -"}</p>
                      <p>{row?.Ownership ? row.Ownership : "-"}</p>
                      <p>
                        {row?.kind_of_property ? row.kind_of_property : "- "}
                      </p>
                      <p>{row?.offer_type ? row.offer_type : " -"}</p>
                    </div>
                  </div>
                </div>
                <div className={classes.basic_details}>
                  <p>Property Details</p>
                  <div className={classes.basic_details_container}>
                    <div className={classes.left}>
                      <p>Property Coordinates</p>
                      <p>Property Location</p>
                      <p>Property Area</p>
                      <p>Availability</p>
                      <p>Floor Details</p>
                      <p>No. of Rooms</p>
                      <p>No. of washrooms</p>
                      <p>Furnishing Status</p>
                      <p>Age of Property</p>
                    </div>
                    <div className={classes.right}>
                      <p>
                        {row?.property_cordinates?.coordinates?.[0] &&
                        row?.property_cordinates?.coordinates?.[1]
                          ? `${row.property_cordinates.coordinates[0]}, ${row.property_cordinates.coordinates[1]}`
                          : "-"}
                      </p>
                      <p>{row?.location ? row.location : "-"}</p>
                      <p>
                        {row?.area_of_property ? row.area_of_property : "-"}
                      </p>
                      <p>{row?.availability ? row.availability : "-"}</p>
                      <p>
                        {row?.floor_details?.total_floors
                          ? row.floor_details.total_floors
                          : "-"}
                      </p>
                      <p>
                        {row?.room_details?.no_of_bedrooms
                          ? row.room_details.no_of_bedrooms
                          : "-"}
                      </p>
                      <p>
                        {row?.room_details?.no_of_washrooms
                          ? row.room_details.no_of_washrooms
                          : "-"}
                      </p>
                      <p>
                        {row?.room_details?.furnishing_status
                          ? row.room_details.furnishing_status
                          : "-"}
                      </p>
                      <p>{row?.age_of_property ? row.age_of_property : "-"}</p>
                    </div>
                  </div>
                </div>
                <div className={classes.basic_details}>
                  <p>Amenities</p>
                  <div className={classes.basic_details_container}>
                    <div className={classes.left}>
                      <p>Amenities</p>
                      <p>Other Features</p>
                      <p>Property facing</p>
                      <p>Location Advantages</p>
                      <p>Water Resources</p>
                      <p>Extra Details</p>
                    </div>
                    <div className={classes.right}>
                      <p>{row?.amenities[0]}</p>
                      <p>{row?.other_features[0]}</p>
                      <p>{row?.property_facing}</p>
                      <p>{row?.location_advantages[0]}</p>
                      <p>{row?.water_source}</p>
                      <p>-</p>
                    </div>
                  </div>
                </div>
                <div className={classes.basic_details}>
                  <p>Photos / Videos</p>
                  <div className={classes.basic_details_image}>
                    {row?.photos?.length > 0
                      ? row?.photos?.map((value) => (
                          <img
                            src={`https://api.gazam.apps.org.in/api/${value.image}`}
                            alt=""
                          />
                        ))
                      : ""}
                    {row?.videos?.length > 0
                      ? row?.videos?.map((value) => (
                          <video controls>
                            <source
                              src={`https://api.gazam.apps.org.in/api/${value.video_name}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ))
                      : ""}
                  </div>
                </div>
                <div className={classes.basic_details}>
                  <p>Pricing</p>
                  <div className={classes.basic_details_container}>
                    <div className={classes.left}>
                      {row?.kind_of_property === "Plots/lands" ? (
                        <>
                          <p>Approval Authority</p>
                          <p>Pre-Leased / Pre-Rented</p>
                        </>
                      ) : (
                        <>
                          <p>Pricing</p>
                        </>
                      )}
                    </div>
                    <div className={classes.right}>
                      {row?.kind_of_property === "Plots/lands" ? (
                        <>
                          <p>{row?.approved_authority}</p>
                          <p>{row?.pre_released_pre_rented}</p>
                        </>
                      ) : (
                        <>
                          <p>{row?.price.expected_price}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
