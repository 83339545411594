import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import classes from "./LogDetail.module.css";
import { CloseProperttIcon, DeleteIcon } from "../../Assets/Icons/icons";
import dummy_image from "../../Assets/Images/dummy_image.png";
import moment from "moment";
const style = {
  bgcolor: "background.paper",
};

export default function LogDetails({
  ViewDetails,
  handleClose,
  row,
  bg,
}) {
  console.log(row);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={ViewDetails}
        onClose={handleClose}
        closeAfterTransition
        // slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: bg, // Custom backdrop color
              border: "1px solid #8ca944 !important",
            },
            timeout: 500,
          },
        }}
      >
        <Fade in={ViewDetails}>
          <Box sx={style} className={classes.detail_container}>
            <div className={classes.detail_main_container}>
              <div className={classes.closeIcon} onClick={handleClose}>
                <CloseProperttIcon />
              </div>
              <p className={classes.detail_heading}>View Logs</p>
             
             {
              row?.log?.map((log, index) => (
                <div key={index} className={classes.log_container}>
                  <div className={classes.log_type}>
                    <p className={classes.log_heading}>Action :</p>
                    <p>{log?.property_log}</p>
                  </div>
                  <div className={classes.log_type}>
                    <p className={classes.log_heading}>Time :</p>
                    <p>{moment(log?.action_date).format("DD-MM-YYYY hh:mm:ss A")}</p>


                  </div>
                </div>
              )) || (
                <div className={classes.no_logs_container}>
                  <p>No logs available</p>
                </div>
              )}
             
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
