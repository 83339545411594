import {
  Typography,
  Box,
  MenuItem,
  Button,
  Grid,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./propertys.module.css";
import { ListFilterIcon, WhiteSearchIcon } from "../../Assets/Icons/icons";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MyTableContainer from "./MyTableContainer";
import { useDispatch, useSelector } from "react-redux";
import { FaFilterCircleXmark } from "react-icons/fa6";
import {
  getAllFiltersList,
  getAllProperties,
  getFeildsList,
  saveInputFilter,
  saveSelectedDate,
  saveSortOrder,
} from "../../Redux/Actions/propertyAction";
import { range } from "../../Utilities/commonConstants";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FaRegCalendarAlt } from "react-icons/fa";

let arr = [1, 2, 3, 4, 5];

let test_users = [
  {
    name: "Manoj",
  },
  {
    name: "Krishna",
  },
  {
    name: "Sri",
  },
  {
    name: "Arun Prasanth",
  },
  {
    name: "Mythili",
  },
  {
    name: "Shiva",
  },
  {
    name: "Trisha",
  },
  {
    name: "Bharath kumar",
  },
];

const PropertyIndex = () => {
  // const [inputs, setInputs] = useState({
  //   category: [],
  //   property_type: [],
  //   location: [],
  //   city: [],
  //   posted_by: [],
  //   area: [],
  //   price: [],
  //   status: [],
  //   owner_type: [],
  // });
  const statusList = [
    "Active",
    "Under_Verification",
    "Pending_Approval",
    "Expired",
    "Deleted",
    "Sold",
    "Deactivated",
    "Drafted",
  ];
  const [errorAlert, setErrorAlert] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [selectedDate, setSelectedDate] = useState();
  // const [fromDate, setFromDate] = useState();
  // const [toDate, setToDate] = useState();
  const [focusedElement, setFocusedElement] = useState();
  const [myUser, setMyUser] = useState();
  const dispatch = useDispatch();
  const {
    propertyList,
    totalCount,
    feildsList,
    filtersList,
    inputs,
    fromDate,
    toDate,
    tableSort,
  } = useSelector((state) => state.propertyData);

  const handleFocus = (name) => {
    setFocusedElement(name);
  };

  console.log(filtersList);

  const handleEvent = (event, picker) => {
    // Save the selected date range to the store
    dispatch(
      saveSelectedDate(
        picker.startDate._d.toISOString(),
        picker.endDate._d.toISOString()
      )
    );

    // If you want to store the dates and avoid calling `getAllProperties`,
    // just remove or comment out the below code.
    // dispatch(
    //   getAllProperties(
    //     setErrorAlert,
    //     pagination,
    //     inputs,
    //     tableSort,
    //     moment(picker.startDate._d.toISOString()).format("YYYY-MM-DD"),
    //     moment(picker.endDate._d.toISOString()).format("YYYY-MM-DD")
    //   )
    // );
  };

  useEffect(() => {
    if (
      fromDate !== undefined &&
      toDate !== undefined &&
      fromDate !== null &&
      toDate !== null
    ) {
      setSelectedDate(
        `${moment(fromDate).format("YYYY-MM-DD")} to ${moment(toDate).format(
          "YYYY-MM-DD"
        )}`
      );
    }
  }, [fromDate, toDate]);

  const handleBlur = () => {
    setFocusedElement(null);
  };

  const handleChange = (field, value, list) => {
    value = value || []; // Ensure it's an array

    if (!Array.isArray(value)) {
      value = [value]; // Convert to array if it's a single value
    }

    if (value.includes("all")) {
      if (inputs[field]?.length === list.length) {
        dispatch(saveInputFilter({ ...inputs, [field]: [] }));
        return;
      }
      let data = list.map((item) =>
        field === "photo" ? item : item._id
      );
      dispatch(saveInputFilter({ ...inputs, [field]: data }));
      return;
    }

    dispatch(saveInputFilter({ ...inputs, [field]: value }));
  };

  const handleChangePagination = (e, count) => {
    setPagination(count);
  };

  function handleGetPropertyList(filters, clear) {
    console.log(filters);

    dispatch(
      getAllProperties(
        setErrorAlert,
        pagination,
        filters,
        tableSort,
        clear ? null : fromDate && moment(fromDate).format("YYYY-MM-DD"),
        clear ? null : toDate && moment(toDate).format("YYYY-MM-DD")
      )
    );
  }

  useEffect(() => {
    handleGetPropertyList(inputs);
  }, [pagination, tableSort]);

  useEffect(() => {
    dispatch(getFeildsList(setErrorAlert));
    dispatch(getAllFiltersList(setErrorAlert));
  }, []);

  const handleNext = () => {
    handleGetPropertyList(inputs);
  };

  const handleClearFilter = async () => {
    const filter = {
      category: [],
      property_type: [],
      location: [],
      city: [],
      posted_by: [],
      area: [],
      price: [],
      status: [],
      owner_type: [],
      photo: [],
    };
    setSelectedDate();
    // setFromDate();
    // setToDate();
    dispatch(saveSelectedDate(null, null));
    // setInputs(filter);
    dispatch(saveInputFilter(filter));
    dispatch(saveSortOrder("createdAt-desc"));
    handleGetPropertyList(filter, "clear");
  };

  return (
    <>
      <Typography className={classes.list_header}>Property List </Typography>
      <Box className={classes.filter_part}>
        <div className={classes.filterHeaderdiv}>
          <Typography className={classes.sub_header}>
            <ListFilterIcon /> Filter
          </Typography>
          <Typography
            className={`${classes.sub_header} ${classes.clearFilterBtn}`}
            onClick={handleClearFilter}
          >
            <FaFilterCircleXmark /> Clear Filter
          </Typography>
        </div>
        <Box mt={2}>
          <ValidatorForm
            useref="form"
            onSubmit={handleNext}
            className={classes.SignUpForm}
          >
            {/* <Box className={classes.filter_list}> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth className="form-c">
                  <InputLabel
                    id="demo-simple-select-label"
                    className={`${classes.InputLabel} ${
                      (focusedElement === "category" ||
                        inputs["category"]?.length > 0) &&
                      classes.focuedValueLable
                    }`}
                  >
                    Listing Type
                  </InputLabel>
                  <Select
                    variant="outlined"
                    className={`${classes.selectInput} ${
                      focusedElement === "category" && classes.focusedInput
                    }`}
                    onFocus={() => handleFocus("category")}
                    onBlur={handleBlur}
                    key={"category"}
                    autoComplete="off"
                    select
                    // label="Category"
                    fullWidth
                    id="emailInput"
                    name={"category"}
                    size="normal"
                    multiple
                    renderValue={(selected) => {
                      if (inputs["category"]?.length === 0) {
                        return (
                          <em style={{ color: "rgba(0,0,0,0.3)" }}>Category</em>
                        );
                      }
                      return (
                        <em style={{ textTransform: "capitalize" }}>
                          {inputs["category"]
                            ?.map((value) => {
                              const item = feildsList?.offer_type.find(
                                (item) => item?._id === value
                              );
                              return item?.text;
                            })
                            .join(", ")}
                        </em>
                      );
                    }}
                    value={inputs["category"]}
                    onChange={(e) =>
                      handleChange(
                        "category",
                        e.target.value,
                        feildsList?.offer_type
                      )
                    }
                    validators={["required"]} // Add this line
                    errorMessages={["Amenities is required"]} // Add this line
                  >
                    <MenuItem
                      value={"all"}
                      className={`${classes.CheckBoxContainer} ${
                        inputs["category"]?.length ===
                          feildsList?.offer_type?.length &&
                        classes.CheckBoxActiveSelectAllContainer
                      }`}
                    >
                      <Checkbox
                        sx={{ padding: 0 }}
                        color="primary"
                        checked={
                          inputs["category"]?.length ===
                          feildsList?.offer_type?.length
                        }
                        classes={{
                          checked: classes.checked,
                        }}
                      />
                      <span>Select All</span>
                    </MenuItem>
                    {feildsList?.offer_type?.map((item) => (
                      <MenuItem
                        value={item?._id}
                        key={item?._id}
                        className={classes.CheckBoxContainer}
                      >
                        <Checkbox
                          sx={{ padding: 0 }}
                          color="primary"
                          checked={inputs["category"]?.includes(item?._id)}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                        <span
                          className={`${
                            inputs["category"]?.includes(item?._id) &&
                            classes.checkboxText
                          }`}
                        >
                          {item?.text}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <TextValidator
                  key={"category"}
                  variant="outlined"
                  autoComplete="off"
                  select
                  fullWidth
                  id="emailInput"
                  placeholder="Expected by"
                  className={`my-field select-field`}
                  name={"category"}
                  value={inputs["category"]}
                  onChange={(e) => handleChange("category", e.target.value)}
                  size="normal"
                >
                  <MenuItem value={"-1"}>
                    <p style={{ color: "rgba(0,0,0,0.3)" }}>Category</p>
                  </MenuItem>
                  {feildsList?.offer_type.map((item, index) => (
                    <MenuItem value={item._id} key={index}>
                      {" "}
                      {item.text}
                    </MenuItem>
                  ))}
                </TextValidator> */}
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth className="form-c">
                  <InputLabel
                    id="demo-simple-select-label"
                    className={`${classes.InputLabel} ${
                      (focusedElement === "property_type" ||
                        inputs["property_type"]?.length > 0) &&
                      classes.focuedValueLable
                    }`}
                  >
                    Property Type
                  </InputLabel>
                  <Select
                    variant="outlined"
                    className={`${classes.selectInput} ${
                      focusedElement === "property_type" && classes.focusedInput
                    }`}
                    onFocus={() => handleFocus("property_type")}
                    onBlur={handleBlur}
                    key={"property_type"}
                    autoComplete="off"
                    select
                    fullWidth
                    id="emailInput"
                    placeholder="Expected by"
                    name={"property_type"}
                    size="normal"
                    multiple
                    renderValue={(selected) => {
                      if (inputs["property_type"]?.length === 0) {
                        return (
                          <em style={{ color: "rgba(0,0,0,0.3)" }}>Category</em>
                        );
                      }
                      return (
                        <em style={{ textTransform: "capitalize" }}>
                          {inputs["property_type"]
                            ?.map((value) => {
                              const item = feildsList?.property_type.find(
                                (item) => item?._id === value
                              );
                              return item?.text;
                            })
                            .join(", ")}
                        </em>
                      );
                    }}
                    value={inputs["property_type"]}
                    onChange={(e) =>
                      handleChange(
                        "property_type",
                        e.target.value,
                        feildsList?.property_type
                      )
                    }
                    validators={["required"]} // Add this line
                    errorMessages={["Amenities is required"]} // Add this line
                  >
                    <MenuItem
                      value={"all"}
                      className={`${classes.CheckBoxContainer} ${
                        inputs["property_type"]?.length ===
                          feildsList?.property_type?.length &&
                        classes.CheckBoxActiveSelectAllContainer
                      }`}
                    >
                      <Checkbox
                        sx={{ padding: 0 }}
                        color="primary"
                        checked={
                          inputs["property_type"]?.length ===
                          feildsList?.property_type?.length
                        }
                        classes={{
                          checked: classes.checked,
                        }}
                      />
                      <span>Select All</span>
                    </MenuItem>
                    {feildsList?.property_type?.map((item) => (
                      <MenuItem
                        value={item?._id}
                        key={item?._id}
                        className={classes.CheckBoxContainer}
                      >
                        <Checkbox
                          sx={{ padding: 0 }}
                          color="primary"
                          checked={inputs["property_type"]?.includes(item?._id)}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                        <span
                          className={`${
                            inputs["property_type"]?.includes(item?._id) &&
                            classes.checkboxText
                          }`}
                        >
                          {item?.text}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <TextValidator
                  key={"property_type"}
                  variant="outlined"
                  autoComplete="off"
                  select
                  fullWidth
                  id="emailInput"
                  placeholder="Expected by"
                  className={`my-field select-field`}
                  name={"property_type"}
                  value={inputs["property_type"]}
                  size="normal"
                  onChange={(e) =>
                    handleChange("property_type", e.target.value)
                  }
                >
                  <MenuItem value={"-1"}>
                    <p style={{ color: "rgba(0,0,0,0.3)" }}>Property type</p>
                  </MenuItem>
                  {feildsList?.property_type.map((item, index) => (
                    <MenuItem value={item._id} key={index}>
                      {" "}
                      {item.text}
                    </MenuItem>
                  ))}
                </TextValidator> */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  {/* <InputLabel
                    id="demo-simple-select-label"
                    // className={`${classes.InputLabel} ${
                    //   (focusedElement === "owner_type" ||
                    //     inputs["owner_type"]?.length > 0) &&
                    //   classes.focuedValueLable
                    // }`}
                  >
                    User List
                  </InputLabel> */}
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    value={inputs["location"]}
                    onChange={(event, value) => {
                      handleChange(
                        "location",
                        value ? value : "",
                        feildsList?.location
                      );
                      // setMyUser(value);
                    }}
                    onFocus={() => handleFocus("location")}
                    onBlur={handleBlur}
                    options={
                      filtersList &&
                      filtersList?.location &&
                      filtersList?.location?.length > 0
                        ? filtersList?.location?.map((option) => option)
                        : [].map((option) => option)
                    }
                    renderInput={(params) => (
                      <TextField
                        label="location"
                        // placeholder="Select City"
                        className={
                          focusedElement === "location"
                            ? "my-autocomplete-focus my-autocomplete"
                            : "my-autocomplete"
                        }
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth className="form-c">
                  <InputLabel
                    id="demo-simple-select-label"
                    className={`${classes.InputLabel} ${
                      (focusedElement === "photo" ||
                        inputs["photo"]?.length > 0) &&
                      classes.focuedValueLable
                    }`}
                  >
                    Photo
                  </InputLabel>
                  <Select
                    variant="outlined"
                    className={`${classes.selectInput} ${
                      focusedElement === "photo" && classes.focusedInput
                    }`}
                    onFocus={() => handleFocus("photo")}
                    onBlur={handleBlur}
                    key={"photo"}
                    autoComplete="off"
                    select
                    fullWidth
                    id="emailInput"
                    placeholder="Expected by"
                    name={"photo"}
                    size="normal"
                    multiple
                    renderValue={(selected) => {
                      if (inputs["photo"]?.length === 0) {
                        return (
                          <em style={{ color: "rgba(0,0,0,0.3)" }}>Photo</em>
                        );
                      }
                      return (
                        <em style={{ textTransform: "capitalize" }}>
                          {inputs["photo"]
                            ?.map((value) => {
                              const item = filtersList?.photo?.find(
                                (item) => item === value
                              );
                              return item;
                            })
                            .join(", ")}
                        </em>
                      );
                    }}
                    value={inputs["photo"] || []}
                    onChange={(e) =>
                      handleChange("photo", e.target.value, filtersList?.photo)
                    }
                    validators={["required"]} // Add this line if needed
                    errorMessages={["Amenities is required"]} // Add this line if needed
                  >
                    <MenuItem
                      value={"all"}
                      className={`${classes.CheckBoxContainer} ${
                        inputs["photo"]?.length ===
                          filtersList?.photo?.length &&
                        classes.CheckBoxActiveSelectAllContainer
                      }`}
                      onClick={() =>
                        handleChange("photo", ["all"], filtersList?.photo)
                      } // Trigger the 'Select All' logic on click
                    >
                      <Checkbox
                        sx={{ padding: 0 }}
                        color="primary"
                        checked={
                          inputs["photo"]?.length === filtersList?.photo?.length
                        } // Checkbox checked based on selection state
                        classes={{
                          checked: classes.checked,
                        }}
                      />
                      <span>Select All</span>
                    </MenuItem>
                    {filtersList?.photo?.map((item) => (
                      <MenuItem
                        value={item}
                        key={item}
                        className={classes.CheckBoxContainer}
                      >
                        <Checkbox
                          sx={{ padding: 0 }}
                          color="primary"
                          checked={inputs["photo"]?.includes(item)}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                        <span
                          className={`${
                            inputs["photo"]?.includes(item) &&
                            classes.checkboxText
                          }`}
                        >
                          {item}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  {/* <InputLabel
                    id="demo-simple-select-label"
                    // className={`${classes.InputLabel} ${
                    //   (focusedElement === "owner_type" ||
                    //     inputs["owner_type"]?.length > 0) &&
                    //   classes.focuedValueLable
                    // }`}
                  >
                    User List
                  </InputLabel> */}
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    value={inputs["area"]}
                    onChange={(event, value) => {
                      handleChange(
                        "area",
                        value ? value : "",
                        feildsList?.location
                      );
                      // setMyUser(value);
                    }}
                    onFocus={() => handleFocus("area")}
                    onBlur={handleBlur}
                    options={
                      filtersList &&
                      filtersList?.area &&
                      filtersList?.area?.length > 0
                        ? filtersList?.area?.map((option) => option)
                        : [].map((option) => option)
                    }
                    renderInput={(params) => (
                      <TextField
                        label="Locality"
                        // placeholder="Select City"
                        className={
                          focusedElement === "Locality"
                            ? "my-autocomplete-focus my-autocomplete"
                            : "my-autocomplete"
                        }
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth className="form-c">
                  <InputLabel
                    id="demo-simple-select-label"
                    className={`${classes.InputLabel} ${
                      (focusedElement === "posted_by" ||
                        inputs["posted_by"]?.length > 0) &&
                      classes.focuedValueLable
                    }`}
                  >
                    Posted By
                  </InputLabel>
                  <Select
                    variant="outlined"
                    className={`${classes.selectInput} ${
                      focusedElement === "posted_by" && classes.focusedInput
                    }`}
                    onFocus={() => handleFocus("posted_by")}
                    onBlur={handleBlur}
                    key={"posted_by"}
                    autoComplete="off"
                    select
                    fullWidth
                    id="posted_by"
                    placeholder="Expected by"
                    name={"posted_by"}
                    size="normal"
                    multiple
                    renderValue={(selected) => {
                      if (inputs["posted_by"]?.length === 0) {
                        return (
                          <em style={{ color: "rgba(0,0,0,0.3)" }}>Category</em>
                        );
                      }
                      return (
                        <em style={{ textTransform: "capitalize" }}>
                          {inputs["posted_by"]
                            ?.map((value) => {
                              const item = feildsList?.user_type.find(
                                (item) => item._id === value
                              );
                              return item.text;
                            })
                            .join(", ")}
                        </em>
                      );
                    }}
                    value={inputs["posted_by"]}
                    onChange={(e) =>
                      handleChange(
                        "posted_by",
                        e.target.value,
                        feildsList?.user_type
                      )
                    }
                    validators={["required"]} // Add this line
                    errorMessages={["Amenities is required"]} // Add this line
                  >
                    <MenuItem
                      value={"all"}
                      className={`${classes.CheckBoxContainer} ${
                        inputs["posted_by"]?.length ===
                          feildsList?.user_type?.length &&
                        classes.CheckBoxActiveSelectAllContainer
                      }`}
                    >
                      <Checkbox
                        sx={{ padding: 0 }}
                        color="primary"
                        checked={
                          inputs["posted_by"]?.length ===
                          feildsList?.user_type.length
                        }
                        classes={{
                          checked: classes.checked,
                        }}
                      />
                      <span>Select All</span>
                    </MenuItem>
                    {feildsList?.user_type?.map((item) => (
                      <MenuItem
                        value={item?._id}
                        key={item?._id}
                        className={classes.CheckBoxContainer}
                      >
                        <Checkbox
                          sx={{ padding: 0 }}
                          color="primary"
                          checked={inputs["posted_by"]?.includes(item?._id)}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                        <span
                          className={`${
                            inputs["posted_by"]?.includes(item?._id) &&
                            classes.checkboxText
                          }`}
                        >
                          {item?.text}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <TextValidator
                  key={"posted_by"}
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  select
                  id="emailInput"
                  placeholder="Expected by"
                  className={`my-field select-field`}
                  name={"posted_by"}
                  value={inputs["posted_by"]}
                  size="normal"
                  onChange={(e) => handleChange("posted_by", e.target.value)}
                >
                  <MenuItem value={"-1"}>
                    <p style={{ color: "rgba(0,0,0,0.3)" }}>Posted By</p>
                  </MenuItem>
                  {feildsList?.user_type.map((item, index) => (
                    <MenuItem value={item._id} key={index}>
                      {" "}
                      {item.text}
                    </MenuItem>
                  ))}
                </TextValidator> */}
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth className="form-c">
                  <InputLabel
                    id="demo-simple-select-label"
                    className={`${classes.InputLabel} ${
                      (focusedElement === "owner_type" ||
                        inputs["owner_type"]?.length > 0) &&
                      classes.focuedValueLable
                    }`}
                  >
                    Owner Type
                  </InputLabel>
                  <Select
                    variant="outlined"
                    className={`${classes.selectInput} ${
                      focusedElement === "owner_type" && classes.focusedInput
                    }`}
                    onFocus={() => handleFocus("owner_type")}
                    onBlur={handleBlur}
                    key={"owner_type"}
                    autoComplete="off"
                    select
                    fullWidth
                    id="emailInput"
                    placeholder="Expected by"
                    name={"owner_type"}
                    size="normal"
                    multiple
                    renderValue={(selected) => {
                      if (inputs["owner_type"]?.length === 0) {
                        return (
                          <em style={{ color: "rgba(0,0,0,0.3)" }}>Category</em>
                        );
                      }
                      return (
                        <em style={{ textTransform: "capitalize" }}>
                          {inputs["owner_type"]
                            ?.map((value) => {
                              const item = feildsList?.ownership.find(
                                (item) => item?._id === value
                              );
                              return item?.text;
                            })
                            .join(", ")}
                        </em>
                      );
                    }}
                    value={inputs["owner_type"]}
                    onChange={(e) =>
                      handleChange(
                        "owner_type",
                        e.target.value,
                        feildsList?.ownership
                      )
                    }
                    validators={["required"]} // Add this line
                    errorMessages={["Amenities is required"]} // Add this line
                  >
                    <MenuItem
                      value={"all"}
                      className={`${classes.CheckBoxContainer} ${
                        inputs["owner_type"]?.length ===
                          feildsList?.ownership?.length &&
                        classes.CheckBoxActiveSelectAllContainer
                      }`}
                    >
                      <Checkbox
                        sx={{ padding: 0 }}
                        color="primary"
                        checked={
                          inputs["owner_type"]?.length ===
                          feildsList?.ownership?.length
                        }
                        classes={{
                          checked: classes.checked,
                        }}
                      />
                      <span>Select All</span>
                    </MenuItem>
                    {feildsList?.ownership?.map((item) => (
                      <MenuItem
                        value={item?._id}
                        key={item?._id}
                        className={classes.CheckBoxContainer}
                      >
                        <Checkbox
                          sx={{ padding: 0 }}
                          color="primary"
                          checked={inputs["owner_type"]?.includes(item?._id)}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                        <span
                          className={`${
                            inputs["owner_type"]?.includes(item?._id) &&
                            classes.checkboxText
                          }`}
                        >
                          {item?.text}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <TextValidator
                  key={"owner_type"}
                  variant="outlined"
                  autoComplete="off"
                  select
                  id="emailInput"
                  placeholder="Expected by"
                  className={`my-field select-field`}
                  name={"owner_type"}
                  fullWidth
                  value={inputs["owner_type"]}
                  size="normal"
                  onChange={(e) => handleChange("owner_type", e.target.value)}
                >
                  <MenuItem value={"-1"}>
                    <p style={{ color: "rgba(0,0,0,0.3)" }}>Owner type</p>
                  </MenuItem>
                  {feildsList?.ownership.map((item, index) => (
                    <MenuItem value={item._id} key={index}>
                      {" "}
                      {item.text}
                    </MenuItem>
                  ))}
                </TextValidator> */}
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  {/* <InputLabel
                    id="demo-simple-select-label"
                    // className={`${classes.InputLabel} ${
                    //   (focusedElement === "owner_type" ||
                    //     inputs["owner_type"]?.length > 0) &&
                    //   classes.focuedValueLable
                    // }`}
                  >
                    User List
                  </InputLabel> */}
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    value={inputs["price"]}
                    onChange={(event, value) => {
                      handleChange(
                        "price",
                        value ? value : "",
                        feildsList?.Price
                      );
                      // setMyUser(value);
                    }}
                    onFocus={() => handleFocus("price")}
                    onBlur={handleBlur}
                    options={
                      filtersList &&
                      filtersList?.Price &&
                      filtersList?.Price?.length > 0
                        ? filtersList?.Price?.map((option) => option)
                        : [].map((option) => option)
                    }
                    renderInput={(params) => (
                      <TextField
                        label="Price"
                        // placeholder="Select City"
                        className={
                          focusedElement === "Price"
                            ? "my-autocomplete-focus my-autocomplete"
                            : "my-autocomplete"
                        }
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              ;
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  {/* <InputLabel
                    id="demo-simple-select-label"
                    // className={`${classes.InputLabel} ${
                    //   (focusedElement === "owner_type" ||
                    //     inputs["owner_type"]?.length > 0) &&
                    //   classes.focuedValueLable
                    // }`}
                  >
                    User List
                  </InputLabel> */}
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    value={inputs["status"]}
                    onChange={(event, value) => {
                      handleChange(
                        "status",
                        value ? value : "",
                        feildsList?.price
                      );
                      // setMyUser(value);
                    }}
                    onFocus={() => handleFocus("status")}
                    onBlur={handleBlur}
                    options={
                      statusList && statusList?.length > 0
                        ? statusList?.map((option) => option)
                        : [].map((option) => option)
                    }
                    renderInput={(params) => (
                      <TextField
                        label="Status"
                        // placeholder="Select City"
                        className={
                          focusedElement === "Status"
                            ? "my-autocomplete-focus my-autocomplete"
                            : "my-autocomplete"
                        }
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              ;
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  {/* <InputLabel
                    id="demo-simple-select-label"
                    // className={`${classes.InputLabel} ${
                    //   (focusedElement === "owner_type" ||
                    //     inputs["owner_type"]?.length > 0) &&
                    //   classes.focuedValueLable
                    // }`}
                  >
                    User List
                  </InputLabel> */}
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    value={inputs["user"]}
                    onChange={(event, value) => {
                      handleChange(
                        "user",
                        value ? value : "",
                        feildsList?.ownership
                      );
                      // setMyUser(value);
                    }}
                    onFocus={() => handleFocus("myuser")}
                    onBlur={handleBlur}
                    options={
                      filtersList &&
                      filtersList?.userList &&
                      filtersList?.userList?.length > 0
                        ? filtersList?.userList?.map((option) => option)
                        : [].map((option) => option)
                    }
                    renderInput={(params) => (
                      <TextField
                        label="User"
                        // placeholder="Select City"
                        className={
                          focusedElement === "myuser"
                            ? "my-autocomplete-focus my-autocomplete"
                            : "my-autocomplete"
                        }
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <div className={classes.datePicker1}>
                  <FormControl fullWidth>
                    <DateRangePicker
                      initialSettings={{ ranges: range }}
                      alwaysShowCalendars={true}
                      onApply={handleEvent}
                      sx={{ width: "100%" }} // Adjust the width here as needed
                    >
                      <div>
                        <TextField
                          sx={{
                            width: "100%", // This ensures the text field takes up the full width of its container
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          size="small"
                          placeholder="Today"
                          value={selectedDate ? selectedDate : ""}
                          className={`${selectedDate ? classes.dataInput : ""}`}
                        />
                        <span
                          className={classes.datePickerCalendarIcon}
                          style={{ right: "10px" }}
                        >
                          <FaRegCalendarAlt />{" "}
                        </span>
                      </div>
                    </DateRangePicker>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <div className={classes.search_btn_container}>
              <Button className={classes.search_btn} type="submit">
                <WhiteSearchIcon /> Search
              </Button>
            </div>
          </ValidatorForm>
        </Box>
      </Box>
      <Box className={classes.table_part}>
        <MyTableContainer
          arr={arr}
          tableDatas={propertyList}
          handleChangePagination={handleChangePagination}
          totalCount={totalCount}
          handleEvent={handleEvent}
          selectedDate={selectedDate}
          handleGetPropertyList={handleGetPropertyList}
        />
      </Box>
    </>
  );
};

export default PropertyIndex;
