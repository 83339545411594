import {
  Popover,
  Radio,
  TableCell,
  TableRow,
  Button,
  Typography,
  Box,
  Checkbox,
  Chip,
} from "@mui/material";
import { MdOutlineRadioButtonUnchecked, MdRemoveRedEye } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";
import classes from "./propertys.module.css";
import {
  ApproveIcon,
  DeactivateIcon,
  DeleteIcon,
  EditIcon,
  SoldIcon,
  ViewMoreIcon,
} from "../../Assets/Icons/icons";
import Room from "../../Assets/Images/Room.png";
import { useState } from "react";
import { dateConversion } from "../../Utilities/commonFunctions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  deleteProperty,
  downloadPropertyDocs,
  sendPropertyRemainder,
  updatePropertyApi,
} from "../../Redux/Actions/propertyAction";
import ConfirmModal from "../../Components/confirmModel";
import moment from "moment";
import { IoMdDownload } from "react-icons/io";
import SendRemainderPop from "../popups/SendRemainderPop";
import { SEND_REMAINDER } from "../../Redux/Constants/propertyConstants";
import PropertyDetails from "../../Components/propertyDetail/PropertyDetails";
import LogDetails from "../../Components/logDetail/LogDetails";

function MyTabelData({
  row,
  index,
  handleGetPropertyList,
  checkedProps,
  handleCheckProps,
  selectTableName,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [remainderPop, setRemainderPop] = useState(false);
  const [ViewDetails, setViewDetails] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [logDetails,setLogDetails] = useState(false);
  const [remainderDetails, setRemainderDetails] = useState("");
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { remainderStatus } = useSelector((state) => state.propertyData);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const CloseAlert = () => {
    setDeleteConfirmationModal(false);
  };

  const handleClickDelete = () => {
    const id = window.location.pathname.split("/").pop();
    dispatch(pageLoader(true));
    dispatch(deleteProperty(row._id, setErrorAlert));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemainderClose = () => {
    setRemainderPop(false);
    setRemainderDetails("");
    dispatch({
      type: SEND_REMAINDER,
      payload: null,
    });
  };

  const handleRemainderChange = (e) => {
    setRemainderDetails(e.target.value);
  };

  const handleClickOption = (data) => {
    const userEntry = {
      property_status: data,
    };
    dispatch(pageLoader(true));
    dispatch(
      updatePropertyApi(
        row._id,
        userEntry,
        setErrorAlert,
        setSuccessAlert,
        handleGetPropertyList
      )
    );
    setAnchorEl(null);
  };

  const handleDocuments = () => {
    dispatch(downloadPropertyDocs(row._id));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleSendRemainder = () => {
    let userEntry = {
      id: row._id,
      message: remainderDetails,
    };
    dispatch(sendPropertyRemainder(userEntry));
  };
  const handleClose2 = () => {
    setViewDetails(false);
  };
  const handleOpen = () => {
    setViewDetails(true);
  };
  const handleLogClose2 = () => {
    setLogDetails(false);
  };
  const handleLogOpen = () => {
    setLogDetails(true);
  };
  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        className={classes.table_body}
        // onClick={() => navigate(`/property/view-property/${row._id}`)}
      >
        <TableCell align="right">
          <Checkbox
            icon={<MdOutlineRadioButtonUnchecked />}
            checkedIcon={<MdCheckCircle />}
            checked={checkedProps?.includes(row._id)}
            onChange={(e) => handleCheckProps(e, row)}
          />
        </TableCell>
        {selectTableName[0]?.active && (
          <TableCell style={{ minWidth: "150px" }}>
            <Typography sx={{ textDecoration: "underline", cursor: "pointer" }}>
              {row?._id.slice(0, 8)}
            </Typography>
          </TableCell>
        )}
        {selectTableName[1]?.active && (
          <TableCell style={{ minWidth: "150px" }}>
            <Typography sx={{ textDecoration: "underline", cursor: "pointer" }}>
              {row?.kind_of_property}{" "}
              {row?.property_status == "Sold" ? (
                <Chip
                  label="Sold"
                  size="small"
                  color="success"
                  variant="outlined"
                  sx={{ marginLeft: "10px", width: "50px", fontSize: "10px" }}
                />
              ) : row?.property_status == "Active" ? (
                <Chip
                  size="small"
                  label="Approved"
                  color="primary"
                  variant="outlined"
                  sx={{ marginLeft: "10px", width: "50px", fontSize: "10px" }}
                />
              ) : (
                ""
              )}
            </Typography>
          </TableCell>
        )}
        {selectTableName[2]?.active && (
          <TableCell style={{ minWidth: "150px" }}>
            <Typography sx={{ textDecoration: "underline", cursor: "pointer" }}>
              {row?.offer_type}
            </Typography>
          </TableCell>
        )}

        {selectTableName[3]?.active && (
          <TableCell style={{ minWidth: "160px" }}>{row.location}</TableCell>
        )}
        {selectTableName[4]?.active && (
          <TableCell style={{ minWidth: "160px" }}>
            {row.locality ? row.locality : "-"}
          </TableCell>
        )}
        {selectTableName[5]?.active && (
          <TableCell style={{ minWidth: "160px" }}>{row.user_type}</TableCell>
        )}
        {selectTableName[6]?.active && (
          <TableCell style={{ minWidth: "160px" }}>{row.user_type}</TableCell>
        )}
        {selectTableName[7].key === "User Name" &&
          selectTableName[7]?.active && (
            <TableCell style={{ minWidth: "160px" }}>
              {row.created_user.name}
            </TableCell>
          )}
        {selectTableName[8].key === "Phone No" &&
          selectTableName[8]?.active && (
            <TableCell style={{ minWidth: "160px" }}>
              {row.created_user.phone}
            </TableCell>
          )}
        {selectTableName[9].key === "Email" && selectTableName[9]?.active && (
          <TableCell style={{ minWidth: "160px" }}>
            {row.created_user.email}
          </TableCell>
        )}
        {selectTableName[10]?.active && (
          <TableCell style={{ minWidth: "170px" }}>
            <div className={classes.TableRowImageContainer}>
              <img
                src={
                  row.photos[0]?.image
                    ? `${process.env.REACT_APP_API_URL}/${row.photos[0]?.image}`
                    : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                }
                alt="property-image"
                height={"40px"}
                width={"40px"}
              />
              <Typography>
                {row.photos.length > 0 || row.photos.video > 0 ? "YES" : "NO"}
              </Typography>
            </div>
          </TableCell>
        )}
        {selectTableName[11].key === "Price" && selectTableName[11]?.active && (
          <TableCell style={{ minWidth: "160px" }}>
            {row.price.expected_price}
          </TableCell>
        )}
        {selectTableName[12]?.key === "Posted On" &&
          selectTableName[12]?.active && (
            <TableCell style={{ minWidth: "160px" }}>
              {moment(row.createdAt).format("DD-MM-YYYY")}
            </TableCell>
          )}
        {selectTableName[13]?.active && (
          <TableCell style={{ minWidth: "160px" }}>
            <TableCell style={{ minWidth: "200px" }}>
              {row.property_status === "Active" ? (
                <span className={classes.active_prop}>Active</span>
              ) : row.property_status === "Pending_Approval" ? (
                <span className={classes.pending_prop}>Pending Approval</span>
              ) : row.property_status === "Sold" ? (
                <span className={classes.sold_prop}>Sold</span>
              ) : row.property_status === "Deactivated" ? (
                <span className={classes.deactivate_prop}>Deactivated</span>
              ) : row.property_status === "Deleted" ? (
                <span className={classes.deactivate_prop}>Deleted</span>
              ) : row.property_status === "Drafted" ? (
                <span className={classes.deactivate_prop}>Drafted</span>
              ) : null}
            </TableCell>
          </TableCell>
        )}
        {selectTableName[14].key === "Last Modified Status" &&
          selectTableName[14]?.active && (
            <TableCell style={{ minWidth: "160px" }}>
              {moment(row.updatedAt).format("DD-MM-YYYY")}{" "}
              {row?.last_modification.length > 0 && (
                <Chip
                  size="small"
                  label={row?.last_modification}
                  color="success"
                  variant="outlined"
                  sx={{ marginLeft: "10px", width: "50px", fontSize: "10px" }}
                />
              )}
            </TableCell>
          )}
        <TableCell>
          <Button sx={{ cursor: "pointer" }} onClick={handleClick}>
            <ViewMoreIcon />
          </Button>
          <PropertyDetails
            row={row}
            ViewDetails={ViewDetails}
            handleClose={handleClose2}
            bg={"#f0f2f485"}
          />
          <LogDetails
            row={row}
            ViewDetails={logDetails}
            handleClose={handleLogClose2}
            bg={"#f0f2f485"}
          />
          {row.property_status !== "Deleted" && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {row.property_status === "Sold" ? (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"8px"}
                  p={2}
                >
                  <Button
                    className={classes.popper_btns}
                    onClick={() =>
                      navigate(`/property/edit-property/${row._id}`)
                    }
                  >
                    <EditIcon /> Edit
                  </Button>
                  <Button
                    className={classes.popper_btns}
                    onClick={() => setDeleteConfirmationModal(true)}
                  >
                    <DeleteIcon /> Delete
                  </Button>
                  <Button
                    className={classes.popper_btns}
                    onClick={() => handleClickOption("Active")}
                  >
                    <DeleteIcon />
                    Mark As UnSold
                  </Button>
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"8px"}
                  p={2}
                >
                  {row.property_status === "Pending_Approval" &&
                  row?.user_type !== "Agent" ? (
                    <>
                      <Button
                        className={classes.popper_btns}
                        onClick={handleDocuments}
                      >
                        <IoMdDownload /> Download
                      </Button>
                    </>
                  ) : null}

                  {(row.property_status === "Deactivated" ||
                    row.property_status === "Pending_Approval") && (
                    <Button
                      className={classes.popper_btns}
                      onClick={() => handleClickOption("Active")}
                    >
                      <ApproveIcon /> Approve
                    </Button>
                  )}

                  {row.property_status !== "Drafted" ? (
                    <Button
                      className={classes.popper_btns}
                      onClick={() => {
                        navigate(`/property/edit-property/${row._id}`);
                        setAnchorEl(null);
                      }}
                    >
                      <EditIcon /> Edit
                    </Button>
                  ) : (
                    <Button
                      className={classes.popper_btns}
                      onClick={() => {
                        setRemainderPop(true);
                        setAnchorEl(null);
                      }}
                    >
                      <EditIcon /> Send Reminder
                    </Button>
                  )}

                  {/* Adding the View button */}
                  <Button className={classes.popper_btns} onClick={handleOpen}>
                    <MdRemoveRedEye />
                    View
                  </Button>
                  <Button className={classes.popper_btns} onClick={handleLogOpen}>
                    <MdRemoveRedEye />
                    View log
                  </Button>

                  {row.property_status !== "Pending_Approval" ? (
                    <Button
                      className={classes.popper_btns}
                      onClick={() => handleClickOption("Sold")}
                    >
                      <SoldIcon /> Mark as Sold
                    </Button>
                  ) : null}

                  {row.property_status !== "Deactivated" && (
                    <Button
                      className={classes.popper_btns}
                      onClick={() => handleClickOption("Deactivated")}
                    >
                      <DeactivateIcon /> Deactivate
                    </Button>
                  )}

                  <Button
                    className={classes.popper_btns}
                    onClick={() => setDeleteConfirmationModal(true)}
                  >
                    <DeleteIcon /> Delete
                  </Button>
                </Box>
              )}
            </Popover>
          )}
        </TableCell>
      </TableRow>
      {deleteConfirmationModal && (
        <ConfirmModal
          modalClose={CloseAlert}
          heading={"Delete Confirmation"}
          subHeading={`Are you sure want to delete this property?`}
          callback={handleClickDelete}
        />
      )}
      <SendRemainderPop
        open={remainderPop}
        handleClose={handleRemainderClose}
        handleRemainderChange={handleRemainderChange}
        remainderDetails={remainderDetails}
        handleSendRemainder={handleSendRemainder}
        remainderStatus={remainderStatus}
      />
    </>
  );
}

export default MyTabelData;
